import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut1 extends Component {

render() {
return (

<div class="container">

<div class="row">
<div class="col-md-12 mb-5 mt=5">
<div class="card h-100">
<div class="card-body">
<h2 class="card-title">Upload Images and Documents</h2>
<p class="card-text">This is the first step in managing your evidence files. The first thing to understand is that you information never leaves your device and we never see what you are encrypting and copying to the public node system. This is the basis of why these protocols are valuable. Files may be AES256 encrypted before they are added to the nodes. The decode key is the only way to decode the file. Keep it safe. It will only stay local to your computer so keep it safe. If you need a custodial service to protect you against loss use off-line protection of your decode key.</p>
</div>
<div class="card-footer">
<a href="#more-info" class="btn btn-primary btn-sm">Encrypt Now <i class="fas fa-arrow-right"></i></a>
</div>
</div>
</div>
<div class="col-md-12 mb-5">
<div class="card h-100">
<div class="card-body">
<h2 class="card-title">Attach Testimony</h2>
<p class="card-text">Evidence is best served to the courts with testimony of it's veracity, authenticity and reliability. This feature allows a legally sufficient statement to a timestamped immutable digital file.</p>
</div>
<div class="card-footer">
<a href="#more-info" class="btn btn-primary btn-sm">Testify Now <i class="fas fa-arrow-right"></i></a>
</div>
</div>
</div>
<div class="col-md-12 mb-5">
<div class="card h-100">
<div class="card-body">
<h2 class="card-title">Encrypt and Decode Files</h2>
<p class="card-text">This option will copy your encrypted content to IPFS and the user will receive a file-locator hash. No record will be made to the public Ethereum blockchain. That requires and additional step.</p>
</div>
<div class="card-footer">
<a href="#more-info" class="btn btn-primary btn-sm">Encrypt Files Now <i class="fas fa-arrow-right"></i></a>
</div>
</div>
</div>

</div>
<hr></hr>
</div>


);
}
}

export default Gut1;

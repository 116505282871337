import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut2 extends Component {

render() {
return (

<div class="container">

<div class="row">
<div class="col-md-12 mt-5 mb-5">
<div class="card h-100">
<div class="card-body">
<h2 class="card-title">Re-encrypt files</h2>
<p class="card-text">Allows for down-the-line decoding and viewing. <span role="img" aria-label="gaspump">&#x26fd;</span></p>
</div>
<div class="card-footer">
<a href="#more-info" class="btn btn-primary btn-sm">Re-encrypt Now <i class="fas fa-arrow-right"></i></a>
</div>
</div>
</div>
<div class="col-md-12 mb-5">
<div class="card h-100">
<div class="card-body">
<h2 class="card-title">Forward re-encrypted files</h2>
<p class="card-text">Re-encrypted files are are copied to Swarm/IPFS and the public Ethereum blockchain. <span role="img" aria-label="gaspump">&#x26fd;</span></p>
</div>
<div class="card-footer">
<a href="#more-info" class="btn btn-primary btn-sm">Forward Data Package Now <i class="fas fa-arrow-right"></i></a>
</div>
</div>
</div>

<div class="col-md-12 mb-5">
<div class="card h-100">
<div class="card-body">
<h2 class="card-title">Upload unencrypted content</h2>
<p class="card-text">If you want your files to reside unencrypted (not recommended) they will be saved on Swarm/IPFS and the public Ethereum blockchain. <span role="img" aria-label="gaspump">&#x26fd;</span></p>
</div>
<div class="card-footer">
<a href="#more-info" class="btn btn-primary btn-sm">Upload Unencrypted File Now <i class="fas fa-arrow-right"></i></a>
</div>
</div>
</div>

</div>

<hr></hr>
</div>

);
}
}

export default Gut2;

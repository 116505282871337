import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';



class Footer extends Component {

  render() {
    return (


      <footer class="footer text-center bg-light">
      <div class="container">
      <div class="row">
      {/* <div class="col-lg-10 h-100 text-center text-lg-center my-auto">
           <ul class="list-inline mb-2">
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">WorldLaw</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Users</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Evidencer</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Bluefin</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Evidencer</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Gumshoe</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Judiciary</a>
          </li>
          </ul>
          <hr></hr>
      </div> */}
      <div class="col-lg-10 h-100 text-center text-lg-center my-auto">
           <ul class="list-inline mb-0">
          <li class="list-inline-item mr-3">
          <a href="https://twitter.com/tenfinney"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fab fa-twitter-square fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
          <a href="https://github.com/blackboxlaw"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fab fa-github fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
          <a href="https://tenfinney.com"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fab fa-bitcoin fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
          <a href="https://tenfinney.com"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fab fa-ethereum fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
          <a href="https://tenfinney.com"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fas fa-gas-pump fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
          <a href="https://tenfinney.com"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fas fa-dice-d6 fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
          <a href="https://tenfinney.com"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fas fa-upload fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
          <a href="https://tenfinney.com"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fas fa-file-download fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
          <a href="https://tenfinney.com"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fas fa-user-tie fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
          <a href="https://tenfinney.com"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fas fa-shield-alt fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
          <a href="https://tenfinney.com"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fas fa-landmark fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
          <a href="https://tenfinney.com"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fas fa-sliders-h fa-2x fa-fw "></i>
            </a>
          </li>
          </ul>
    <hr></hr>
            <p class="text-muted large mb-4 mb-lg-0">Copyright 2022 &copy; Web3 Legal Engineering</p>
      </div>
      </div>
      </div>

      <div class="d-flex justify-content-center mt-5">

{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<div className="text-black">Current Account: <span id="account">{this.props.account}</span>

</div>
</div>
  </footer>

);
}
}

export default Footer;

import React, { Component } from "react";
import Web3 from "web3";
import "./App.css";
import ContentLoader from "../abis/ContentLoader-2022-constructor.json";
// import ContentLoader from "../abis/ContentLoader.json";
// import ClaimManager from '../abis/ClaimManager.json'
// import EvidenceManagement from '../abis/EvidenceManagement.json'
import Navbar from "./Navbar";
import GutTop from "./GutTop";
import GutBc from "./GutBc";
import Gut1 from "./Gut1";
import Gut2 from "./Gut2";
import Gut3 from "./Gut3";
import Gut4 from "./Gut4";
import Gut5 from "./Gut5";
import GutBot from "./GutBot";
import Footer from "./Footer";

const ipfsClient = require("ipfs-http-client");
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
}); // leaving out the arguments will default to these values

class App extends Component {
  async componentWillMount() {
    await this.loadWeb3();
    await this.loadBlockchainData();
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Web3 browser not detected. Install Metamask extension or try a different browser."
      );
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3;

    // Load account
    const accounts = await web3.eth.getAccounts();
    this.setState({ account: accounts[0] });
    console.log(accounts);

    // Get the network
    const networkId = await web3.eth.net.getId();
    console.log("network id: " + networkId);

    // Get from sol compiled json
    const networkData = ContentLoader.networks[networkId];
    console.log(networkData);

    // Get the smart contract
    if (networkData) {
      const contract = new web3.eth.Contract(
        ContentLoader.abi,
        networkData.address
      );
      this.setState({ contract });
      console.log(contract);

      // Get the content hash
      const contentHash = await contract.methods.getContent().call();
      this.setState({ contentHash });
      console.log(
        "The current content hash is: https://ipfs.infura.io/ipfs/" +
          contentHash
      );

      // Get the transaction hash
      const transactionHash = await web3.eth.getTransaction({});
      this.setState({ transactionHash });
      console.log("transaction hash: " + transactionHash);
    } else {
      window.alert("Smart contract not deployed to detected network.");
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      contentHash: "",
      transactionHash: null,
      contract: null,
      web3: null,
      buffer: null,
      networkId: null,
      account: null,
    };
  }

  captureFile = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      this.setState({ buffer: Buffer(reader.result) });
      console.log("buffer", this.state.buffer);
    };
  };

  onSubmit = (event) => {
    event.preventDefault();
    console.log(
      "Congratulations. Your buffered file was distributed to a world-wide system of public nodes. You will need to complete this process to memorialize the location on the Ethereum blockchain by signing the transaction with your Web3 account. You can install a Web3 browser plugin for Chrome Opera Firefox and Brave browsers if one does not automatically pop into your screen after you pressed submit. Metamask is a plugin that makes signing transactions easy."
    );
    ipfs.add(this.state.buffer, (error, result) => {
      console.log(
        "The result of your file upload can be found at https://ipfs.infura.io/ipfs/<THE-FOLLOWING-CONTENT-HASH-HERE>",
        result
      );
      if (error) {
        console.error(error);
        return;
      }
      this.state.contract.methods
        .setContent(result[0].hash)
        .send({ from: this.state.account })
        .then((r) => {
          return this.setState({ contentHash: result[0].hash });
        });
    });
  };

  render() {
    return (
      <container>
        <div>
          <Navbar account={this.state.account} />

          <div className="container-new mt-5">
            <div className="row">
              <div>
                <GutTop account={this.state.account} />
              </div>

              <main role="main" className="col-lg-12 d-flex text-center">
                <div class="col-lg-12 mt-5 mb-0">
                  <h3 class="my-1 text-primary">
                    Current Evidencer file:<hr></hr>
                  </h3>
                  <div className="content mr-auto ml-auto">
                    <a
                      href="#tidbits-aboutthelivecontent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="justify-content-center text-black">
                        <a href="#more-info">
                          <img
                            class="img-fluid"
                            src={`https://ipfs.infura.io/ipfs/${this.state.contentHash}`}
                            alt="The Content can be found at the following link."
                          ></img>
                        </a>
                      </div>
                    </a>
                    <div className="justify-content-center text-black">
                      https://ipfs.infura.io/ipfs/
                      <span id="contentHash">{this.state.contentHash}</span>
                    </div>
                    <div className="justify-content-center text-danger">
                      <small>
                        "Right Click", "Inspect Element", "Console" to view the transaction hash and details.{" "}
                      </small>
                      <span id="transactionHash">
                        <small>{this.state.transactionHash}</small>
                      </span>
                    </div>
                  </div>
                  <div class="section">
                    <div id="uploading-instructions"></div>
                    <h2 class="text-primary">
                      Do you want your files to be viewed publicly?
                    </h2>
                    <div class="container">
                      <h4 class="text-center mt-0">
                        Uploads are public. Consider encryption before sending.
                      </h4>
                      <div id="content-submit"></div>
                      <hr class="divider my-4"></hr>
                      <div class="row mb-20">
                        <div class="col-lg-6 col-md-6 text-center">
                          <div class="mt-5">
                            <i class="fas fa-wind fa-3x text-primary mb-4"></i>
                            <h3 class="h4 mb-2 text-primary">
                              <strong>
                                Encryption is specialized, so it's up to you to choose.
                              </strong>
                            </h3>
                            <h4 class="textdesc">
                              <small>
                                <strong>
                                  This site does not encrypt files. YOU MUST ENCRYPT YOUR OWN FILES.
                                </strong>{" "}
                                There are several encryption algorithms to choose from.{" "}
                                <a
                                  href="https://freecrypt.org/?p=fenc"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Free Express Encryption
                                </a>{" "} is one tool that makes encrypting a file easy.{" "}
                              </small>
                            </h4>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 text-center">
                          <div class="mt-5">
                            <i class="fas fa-file-image fa-3x text-primary mb-4"></i>
                            <h3 class="h4 mb-2 text-primary">
                              <strong>
                                Files submitted here are stored on a system of public nodes.
                              </strong>
                            </h3>
                            <h4 class="textdesc">
                              <small>
                                This portal is used to send a copy of your file to a public network of node computers. If your file is encrypted no-one will be able to read it's contents. If you send an unencrypted file, the contents will be public.
                              </small>
                            </h4>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 text-center">
                          <div class="mt-5">
                            <i class="fab fa-ethereum fa-3x text-primary mb-4"></i>
                            <h3 class="h4 mb-2 text-primary">
                              Public blockchains keeps track of the content on the network.
                            </h3>
                            <h4 class="textdesc">
                              <small>
                                Once the content file is submitted and stored in a distributed node system, the cryptographic locator files is then passed on to the blockchain and recorded. This allows for other applications to utilize the file for specific operations.
                              </small>
                            </h4>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 text-center">
                          <div class="mt-5">
                            <i class="fas fa-gas-pump fa-3x text-primary mb-4"></i>
                            <h3 class="h4 mb-2 text-primary">
                              Every transaction needs a little fuel to process
                              the transaction.
                            </h3>
                            <h4 class="textdesc">
                              <small>
                                Every transaction that is sent to the blockchain uses a processor that requires a fee to place objects onto the permanent public blockchain. Retrieval of the data is free once the content has been recorded.
                              </small>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="magic"></div>
                    <div className="justify-content-center text-black">
                      <h4 class="textdesc">
                        <small>
                          <strong>
                            DO NOT SUBMIT UNENCRYPTED FILES UNLESS YOU INTEND
                            THEM TO BE PUBLICLY VIEWED
                          </strong>
                        </small>
                      </h4>
                      <br></br>
                    </div>
                    <hr></hr>
                    <section class="call-to-action2 text-white text-center mb-1">
                      <div class="row">
                        <div class="col-md-10 col-lg-12 col-xl-12 mx-auto">
                          <h1 class="text-white">
                            <strong>Select and Submit Content</strong>
                          </h1>
                          <h4 class="textdesc text-white">
                            Browse and select a file now.{" "}
                          </h4>
                          <h6 class="textdesc text-white">
                            <medium>
                              DO NOT submit an unencrypted file unless you
                              intend your file to be publicly accessed and
                              viewed.
                            </medium>
                          </h6>

                          <form onSubmit={this.onSubmit}>
                            <input type="file" onChange={this.captureFile} />
                            <input
                              type="submit"
                              class="btn btn-md btn-primary"
                              value="Submit Content File"
                            />
                          </form>

                          <div className="justify-content-center text-black">
                            <a href="#more-info">
                              <img
                                class="card-img-top50"
                                src="../img/binocs.png"
                                alt="WL-bluefin"
                              ></img>
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <hr></hr>
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6">
                        <img
                          class="img-fluid rounded"
                          src="../img/gears0.png"
                          alt="WL-ltgr-logos-01"
                        ></img>
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <div class="col-lg-6 col-md-6">
                        <h2>
                          There are many great free tools that encrypt
                          information.
                        </h2>
                        <p>
                          The following free on-line tool creates a downloadable
                          encrypted file for you. You will receive a link to an
                          encrypted file to download to your device.{" "}
                          <a
                            href="https://encrypt.one/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            <hr></hr>
                            1. You will receive a link an encrypted file to download to your device. 
                            <hr></hr> 
                            2. Select it and submit it using the button to permanently save it to the public nodes and blockchain.
                            </a>
                            <hr></hr>
                            3. Select that file and submit it usinjg the button to permanently save it to the public nodes and blockchain.
                        </p>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <img
                            class="img-fluid rounded"
                            src="../img/towers.png"
                            alt="WL-ltgr-logos-01"
                          ></img>
                        </div>
                        <div class="col-lg-10">
                          <br></br>
                          <h2>
                            <a
                              href="https://encrypt.one/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Create an uploadable encrypted file here.
                            </a>
                          </h2>
                          <p>
                          <a
                          href="https://encrypt.one/"
                          target="_blank"
                          rel="noopener noreferrer"
                          >
                          You will receive a link to an encrypted file to download to your device.{" "}
                          </a>
                          Once you have an encrypted file you can safely upload it above by selecting "SUBMIT". 
                          <br></br>
                          Select it and submit it usinjg the button to permanently save it to the public nodes and blockchain.
                          <div className="justify-content-center text-info mt-2">
                          <a
                          href="https://freecrypt.org/?p=fdec"
                          target="_blank"
                          rel="noopener noreferrer"
                          >
                          Free Decode Content File AFTER Submit
                          </a>
                          <br></br>
                          <hr></hr>
                          <a href="#uploading-instructions" target="_blank">
                          Instructions Here
                          </a>
                          </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              <div>
                <Gut1 account={this.state.account} />
              </div>
              <div>
                <GutBc account={this.state.account} />
              </div>
              <div>
                <Gut2 account={this.state.account} />
              </div>
              <div>
                <Gut3 account={this.state.account} />
              </div>
              <div>
                <Gut4 account={this.state.account} />
              </div>
              <div>
                <Gut5 account={this.state.account} />
              </div>
              <div>
                <GutBot account={this.state.account} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer account={this.state.account} />
        </div>
      </container>
    );
  }
}

export default App;

import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut3 extends Component {

render() {
return (

<div class="container">

<section class="features-icons bg-light text-center">
<div class="container">
<div class="row mb-5">
<div class="col-md-4">
<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
<div class="features-icons-icon d-flex">
<i class="fas fa-car-crash m-auto fa-4x fa-fw text-primary"></i>
</div>
<h3>Automobile Collisions</h3>
{/* <p class="lead mb-0">tidbits explanatory text here</p> */}
</div>
</div>
<div class="col-md-4">
<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
<div class="features-icons-icon d-flex">
<i class="fas fa-user-injured m-auto fa-4x fa-fw text-primary"></i>
</div>
<h3>Personal Injury and Domestic Violence</h3>
{/* <p class="lead mb-0">tidbits explanatory text here</p> */}
</div>
</div>
<div class="col-md-4">
<div class="features-icons-item mx-auto mb-0 mb-lg-3">
<div class="features-icons-icon d-flex">
<i class="fas fa-house-damage m-auto fa-4x fa-fw text-primary"></i>
</div>
<h3>Property Damage, Negligence and Products Liability</h3>
{/* <p class="lead mb-0">tidbits explanatory text here</p> */}
</div>
</div>
</div>


<section class="features-icons bg-light text-center">
<div class="container">
<div class="row mb-5">
<div class="col-md-4">
<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
<div class="features-icons-icon d-flex">
<i class="fas fa-lock m-auto fa-3x fa-fw text-primary"></i>
</div>

<h3>Encrypted Files Locally</h3>
{/* <p class="lead mb-0">There are many way to manage and expose data on public blockchains. Sometimes users want to have their files pubilcaaly viewed, others want them to stay private while leveraging the public nodes and blockchain for file persistance. Encrypting files and remotely storing them can relieve stress points of managing sensitive files that may need to stay secure while not relying on a centralized service to house your data.</p> */}
</div>
</div>
<div class="col-md-4">
<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
<div class="features-icons-icon d-flex">
<i class="fas fa-link m-auto fa-3x fa-fw text-primary"></i>
</div>
<h3>Copy to Public Nodes</h3>
{/* <p class="lead mb-0">Files are more secure on a public blockchain that uinder your control. Natural disasters, harddrive failures and prying eyes all put personal and confidetial files at risk.  If a proper encryption format and decode keyhanding, files will always be available and remain private unless the user provides a decode key. </p> */}
</div>
</div>
<div class="col-md-4">
<div class="features-icons-item mx-auto mb-0 mb-lg-3">
<div class="features-icons-icon d-flex">
<i class="fas fa-lock-open m-auto fa-3x fa-fw text-primary"></i>
</div>
<h3>Download & Decode</h3>
{/* <p class="lead mb-0">Decoding is easy as long as you cave the decode key and know what type of encryption was used in the generation of the encrypted file.</p> */}
</div>
</div>
</div>

</div>

<hr></hr>
</section>

</div>

</section>

</div>

);
}
}

export default Gut3;

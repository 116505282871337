import React, { Component } from 'react';


class GutTop extends Component {

render() {
return (

<div class="container-fluid">
<section class="call-to-action1 text-white text-center mb-5">

<img class="img-fluid d-block mx-auto" src="../img/logo-m-077-tidbits-wh.png" alt="WL-proofer"></img>


</section>

<div class="row align-items-center my-5">
<div class="col-lg-12 ">

<h1 class="mdtextblack">Prove claims with Evidencer.</h1>
<p class="textdesc"><emgray>Evidencer stores electronic files to be used as admissible evidence in court systems. Every admissible electronic document needs to satisfy rules of evidence which typically requires a reliable and proveable chain-of-custody record. The Evidencer application is a tool for encrypting electronic files the safely storing them on worldwide network of public node computers which are available 24/7/365; anywhere, anytime. </emgray><emblue><strong>No more trusting intermediaries. No username or password is required.</strong></emblue> A user only needs a signing account which is free to get and can be obtained anonomously.</p>
<a class="btn btn-primary" href="#content-submit">Encrypt & Upload Now</a>
</div>

</div>

</div>


);
}
}

export default GutTop;

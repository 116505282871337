import React, { Component } from "react";
import Identicon from "identicon.js";
import makeBlockie from "ethereum-blockies-base64";

const Web3 = require("web3");
const web3 = new Web3(
  "https://mainnet.infura.io/v3/8db5dad32a3f490dac7aaf6cb2b23b82"
);
web3.eth.accounts.create();

const newAccount = web3.eth.accounts.create();
console.log(newAccount);

class GutBot extends Component {
  render() {
    return (
      <div class="container-new">
        <section class="call-to-action text-white text-center" id="executetx">
          <div class="container-new">
            <div class="row">
              <div class="col-xl-12 mx-auto">
                <h5 class="mb-4 text-white">
                  Evidencer are digital packages of data that are encrypted and
                  broken up into pieces that are distributed to a series of
                  public nodes. This protects the data from destruction and
                  allows it be available 24/7/365 presumably forever. The safe
                  storage of tidbits allows a user confidence that when their
                  day-in-court arrives there will be no issues with
                  chain-of-custody challenges. &nbsp;
                  <i class="fab fa-ethereum"></i>
                </h5>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-5">
              {this.props.account ? (
                <img
                  className="ml-1 rounded-5"
                  width="24"
                  height="24"
                  src={makeBlockie(this.props.account, 24).toString()}
                  alt="AcctIconImage"
                />
              ) : (
                <span></span>
              )}

              {this.props.account ? (
                <img
                  className="ml-1 rounded-5 mr-2"
                  width="24"
                  height="24"
                  src={`data:image/png;base64,${new Identicon(
                    this.props.account,
                    24
                  ).toString()}`}
                  alt="AcctIconImage"
                />
              ) : (
                <span></span>
              )}

              <div className="text-white">
                Current Account: <span id="account">{this.props.account}</span>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default GutBot;
